export const getters = {
  selectedFilters(state) {
    const filters = [];

    state.filters.forEach(filter => {
      if (filter.selected) {
        filters.push(filter);
        if (state.subFilters[filter.name] !== undefined) {
          state.subFilters[filter.name].forEach(subFilter => {
            if (subFilter.selected) {
              filters.push(subFilter);
            }
          });
        }
      }
    });

    return filters;
  },

  getSelectedRequiredFilters(state) {
    return state.filters.filter((filter) => filter.selected);
  },

  chosenDataTypeValue(state) {
    return state.chosenDataType.value;
  },

  getFilters(state, getters) {
    let filters = JSON.parse(JSON.stringify(state.filters));
    return filters.map(filter => {
      filter.disabled = false;

      if (
        (getters.chosenDataTypeValue === 'inn' || getters.chosenDataTypeValue === 'ogrn') &&
        state.chosenDataType.value === filter.name
      ) {
        filter.selected = false;
        filter.disabled = true;
        filter.partiallySelected = false;
      }
      return filter;
    })
      .filter(filter => !filter.disabled);
  },

  requiredFilters(state, getters) {
    return getters.selectedFilters
      .filter(filter => {
        if (filter.partiallySelected !== undefined) {
          return !filter.partiallySelected;
        }

        return false;
      });
  },

  settings(state) {
    const filterValue = (selected, partiallySelected, name) => {
      if (!selected || ((state.chosenDataType.value === 'ogrn' ||
        state.chosenDataType.value === 'inn') && name === state.chosenDataType.value)) {
        return 0;
      } else {
        return partiallySelected ? 1 : 2;
      }
    };

    const settings = {};
    state.filters.forEach(filter => {
      settings[filter.name] = filterValue(
        filter.selected,
        filter.partiallySelected,
        filter.name
      );

      if (state.subFilters[filter.name] !== undefined) {
        const filterSelected = filter.selected;
        state.subFilters[filter.name]
          .forEach(filter => {
            settings[filter.name] = filter.selected && filterSelected;
          });
      }
    });
    settings['show_closed'] = state.showClosed &&
      ['phone', 'email'].indexOf(state.chosenDataType.value) === -1;

    return settings;
  },

// нужно для использования в легаси (см. moduls_inn_parser.js)
  clientFileData(state, getters) {
    return {
      name: getters['uploadFile/insideNameOfUploadedFile'],
      column: state.chosenColumn.value,
      dataType: state.chosenDataType.value,
      delimiter: state.chosenDelimiter.symbol
    };
  },

  dataForDemoFile(state, getters) {
    return {
      file: getters.clientFileData,
      filters: getters.settings,
    }
  },

  needDelimiter(state) {
    return ['phone', 'email'].indexOf(state.chosenDataType.value) !== -1;
  },

  filterDataOldFormat(state, getters) {
    return {
      basic: getters.selectedFilters.map(filter => filter.name),
      required: getters.requiredFilters.map(filter => filter.name),
    };
  },

  nothingSelected(state, getters) {
    return (getters.selectedFilters.length === 0);
  },

  getPreloaderText(state) {
    return state.preloaderText || false;
  }
};
