class ExplainHelper {
    static explainPhoneSubFilters(settings)
    {
        if (settings.phoneFilter.name === 'phone') {
            const phoneFilterIsTotallyChosen =
            settings.phoneFilter.selected && !settings.phoneFilter.partiallySelected;
            const selectedSubFilters = settings.phoneSubFilters.filter(
                subFilter => subFilter.selected
            );
            let text = 'Вы получите';
            const delimiter = phoneFilterIsTotallyChosen ? ' или' : ', ';

            selectedSubFilters.forEach((subFilter, index) => {
                let subFilterTitle = subFilter.title;
                if (subFilter.explain) {
                    subFilterTitle += (' ' + subFilter.explain);
                }
                text +=  ' ' + subFilterTitle;
                text += (index === selectedSubFilters.length - 1) ? ' ' : delimiter + ' ';
            });
            text += 'телефоны' + (phoneFilterIsTotallyChosen ?
                '. У всех компаний этого списка будет телефон.' :
                ' у тех компаний, где они есть.');

            return text;
        } else if (settings.phoneFilter.name === 'phone_egrul') {
            const phoneFilterIsTotallyChosen =
                settings.phoneFilter.selected && !settings.phoneFilter.partiallySelected;
            const selectedSubFilters = settings.phoneSubFilters.filter(
                subFilter => subFilter.selected
            );
            let text = 'Вы получите';
            const delimiter = phoneFilterIsTotallyChosen ? ' или' : ', ';

            selectedSubFilters.forEach((subFilter, index) => {
                let subFilterTitle = subFilter.title;
                if (subFilter.explain) {
                    subFilterTitle += (' ' + subFilter.explain);
                }
                text +=  ' ' + subFilterTitle;
                text += (index === selectedSubFilters.length - 1) ? ' ' : delimiter + ' ';
            });
            text += 'телефоны у каждой компании' + (phoneFilterIsTotallyChosen ?
                '. У всех компаний этого списка будет телефон.' :
                ' у тех компаний, где они есть.');

            return text;

        }

        return '';
    }

}

export default ExplainHelper;
