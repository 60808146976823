<template>
  <div>
    <form
      v-if="!fileHasBeenUploaded"
      ref="fileform"
      enctype="multipart/form-data"
      class="upload_dropzone"
      :class="[{'dragover' : isDragover}]"
      @drop="uploadByDrop"
      @dragover="darkenDropzone"
      @dragleave="lightenDropzone"
    >
      <img
        src="/images/icons/simple_shapes/upload-file.svg"
        alt="Загрузить файл"
      >
      <div class="dropzone__upload_file__text">
        Кликните здесь для загрузки или<br>
        перетащите файл в область<br>
        .xlsx
      </div>
      <input
        :id="id"
        type="file"
        title=" "
        class="upload_file_area"
        accept=".xlsx"
        required
        @change="uploadFile"
      >
    </form>
    <div
      v-show="fileHasBeenUploaded"
      class="file_settings_container"
    >
      <div class="file_upload_zone">
        <img
          src="/images/icons/simple_shapes/excel_logo.svg"
          alt="Логотип excel"
        >
        <div class="upload_file_name">
          {{ nameOfUploadedFile }}
        </div>
        <div class="upload_another_file">
          Загрузить другой файл
          <input
            :id="id"
            type="file"
            title=" "
            class="upload_file_area"
            accept=".xlsx"
            required
            @change="uploadFile"
          >
        </div>
      </div>

      <div>
        Выберите тип данных, по которому будет производиться поиск, и столбец,
        в котором эти данные хранятся.
      </div>

      <div class="parsing_parameters">
        <label class="actualization__upload_file_setting_select">
          Тип данных:
          <select
            @change.prevent="chooseDataType"
          >
            <option
              v-for="(dataType, dataTypeIndex) in dataTypes"
              :key="dataTypeIndex + '__' + dataType.value"
              :value="dataType.value"
              :selected="dataType.value === chosenDataType.value"
            >
              {{ dataType.title }}
            </option>
          </select>
        </label>

        <label class="actualization__upload_file_setting_select">
          В столбце:
          <select
            @change.prevent="chooseColumn"
          >
            <option
              v-for="(column, columnIndex) in columns"
              :key="columnIndex + '__' + column.value"
              :value="column.value"
              :selected="column.value === chosenColumn.value"
            >
              {{ column.title }}
            </option>
          </select>
        </label>
        <label
          v-if="needDelimiter"
          class="actualization__upload_file_setting_select actualization__delimiter_symbol"
        >
          Разделитель:
          <select
            @change.prevent="chooseDelimiter"
          >
            <option
              v-for="(delimiter, delimiterIndex) in delimiters"
              :key="delimiterIndex + '__actualization_delimiter'"
              :value="delimiter.value"
              :selected="delimiter.value === chosenDelimiter.value"
              class="actualization__upload_file_setting_option__delimiter"
            >
              {{ delimiter.title }}
            </option>
          </select>
          <img
            src="/images/icons/simple_shapes/question-circle.svg"
            alt="Пояснение"
            @click="toggleShowSeparatorInfo"
          >
          <NotificationComponent
            v-if="isShowSeparatorInfo"
            class="separator_Info"
            display-mode="overlay"
            flipper-position="without-flipper"
          >
            Разделитель — символ, разделяющий несколько
            {{ dataTypeTitleDeclination(chosenDataType, 'ов') }} компании,
            если в ячейках выбранного вами столбца их может быть несколько.
          </NotificationComponent>
        </label>
      </div>
      <UploadedTable
        :rows="xlsxData"
        :columns="columns"
        :chosen-column="indexOfChosenColumn"
        @chooseColumn="chooseColumnByClick"
      />
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';
import NotificationComponent from '@/common_components/NotificationComponent/NotificationComponent.vue';
import UploadedTable from '@/actualization_service/components/UploadDropZone/UploadedTable.vue';

export default {
  name: 'UploadDropZone',
  components: {
    UploadedTable,
    NotificationComponent,
  },

  emits: ['uploadFile'],

  data() {
    return {
      isShowSeparatorInfo: false,
      isDragover: false,
      id: String,
    };
  },

  computed: {
    ...mapState([
      'dataTypes',
      'columns',
      'delimiters',
      'chosenDataType',
      'chosenColumn',
      'chosenDelimiter',
    ]),

    ...mapState('uploadFile', [
      'file',
      'xlsxData',
    ]),

    ...mapGetters([
      'needDelimiter',
    ]),

    ...mapGetters('uploadFile', [
      'nameOfUploadedFile',
    ]),

    fileHasBeenUploaded() {
      return this.file !== undefined;
    },

    indexOfChosenColumn() {
      return Number.parseInt(this.chosenColumn.value, 10);
    },
  },

  created() {
    this.setId();
  },

  mounted() {
    ['drag', 'dragstart', 'dragover', 'dragenter',
      'dragend', 'dragleave', 'drop'].forEach((evt) => {
      this.$refs.fileform.addEventListener(evt, (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    });
  },

  methods: {
    ...mapMutations('uploadFile', ['setFileIsAnalyzed']),

    ...mapMutations([
      'setChosenDataType',
      'setChosenColumn',
      'setChosenDelimiter',
    ]),

    ...mapActions([
      'analyzeClientFile',
    ]),

    toggleShowSeparatorInfo() {
      this.isShowSeparatorInfo = !this.isShowSeparatorInfo;
    },

    analyzeFile() {
      this.analyzeClientFile();
      try {
        window.yaCounter23743225.reachGoal('count_actual');
      } catch (e) {
        console.log(e);
      }
    },

    chooseDataType(e) {
      this.setFileIsAnalyzed(false);
      this.setChosenDataType(e.target.value);
    },

    chooseColumn(e) {
      this.setFileIsAnalyzed(false);
      this.setChosenColumn(e.target.value);
    },

    chooseColumnByClick(columnIndex) {
      this.setFileIsAnalyzed(false);
      this.setChosenColumn(columnIndex);
    },

    chooseDelimiter(e) {
      this.setFileIsAnalyzed(false);
      this.setChosenDelimiter(e.target.value);
    },

    dataTypeTitleDeclination(dataType, ending = '') {
      if (dataType.value === 'phone') {
        return dataType.shortTitle.toLowerCase() + ending;
      }
      if (dataType.value === 'email') {
        return dataType.shortTitle.toLowerCase();
      }

      return dataType.title;
    },

    setId() {
      this.id = `actualization__upload_client_file_${Math.random().toString(36).substr(2)}`;
    },

    uploadByDrop(e) {
      const file = e.dataTransfer.files[0];
      e.dataTransfer.clearData();
      this.isDragover = false;
      this.$emit('uploadFile', file);
    },

    darkenDropzone() {
      this.isDragover = true;
    },

    lightenDropzone() {
      this.isDragover = false;
    },

    uploadFile(e) {
      const file = e.target.files[0];
      this.$emit('uploadFile', file);
    },
  },
};
</script>

<style scoped>
.upload_dropzone {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  background: #eee;
  font-size: 14px;
  font-weight: bold;
  border: 2px dashed #aaa;
  color: #aaa;
}

.dragover {
  background-color: #ddd;
}

.actualization__upload_file_setting_select {
  display: flex;
  align-items: center;
  gap: 5px;
}

.file_upload_zone {
  display: flex;
  align-items: center;
  gap: 5px;
}

.upload_file_name {
  font-size: 16px;
  max-width: 350px;
  overflow: hidden;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload_another_file {
  margin-left: 10px;
  font-size: 12px;
  text-decoration: underline;
  color: var(--color-green-prime, #839F22);
}

.upload_file_area {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

select {
  -webkit-appearance: none;
  padding: 3px 35px 3px 5px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  background-image: url(/images/icons/simple_shapes/arrow-down-white.svg),
                    linear-gradient(270deg, #839f22, #839f22 30px, #fff 0);
  background-repeat: no-repeat, no-repeat;
  background-position: top -10px right -7px, left;
}

.dropzone__upload_file__text {
  line-height: normal;
  font-weight: normal;
  margin-bottom: 8px;
  text-align: center;
}

.file_settings_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.separator_Info {
  bottom: 20px;
}

.parsing_parameters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
</style>
