<template>
  <div class="actualization__dashboard">
    <div
      class="actualization__wrapper"
      :class="[{ 'separate_actualization__wrapper': isSeparatePage }]"
    >
      <div class="actualization__configurator">
        <UploadPanel />
        <Fields />
      </div>
    </div>
    <Banner />
    <StatisticsPanel />
    <Preloader v-if="preloaderShow">
      {{ getPreloaderText }}
    </Preloader>
    <ModalsContainer />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import { ModalsContainer } from 'vue-final-modal';
import Banner from '@/actualization_service/components/Banner/Banner.vue';
import UploadPanel from '../UploadPane/UploadPanel.vue';
import Fields from '../Fields/Fields.vue';
import StatisticsPanel from '../StatisticsPanel/StatisticsPanel.vue';
import Preloader from '../../../common_components/Preloader/Preloader.vue';

export default {
  name: 'CustomerInnOgrnFilter',
  components: {
    ModalsContainer,
    Banner,
    UploadPanel,
    Preloader,
    Fields,
    StatisticsPanel,
  },

  data() {
    return {
      isSeparatePage: false,
    };
  },

  computed: {
    ...mapGetters([
      'getPreloaderText',
    ]),

    ...mapState([
      'preloaderShow',
    ]),
  },

  created() {
    this.checkUrl();
    this.getFilters();
  },

  methods: {

    ...mapActions([
      'getFilters',
    ]),

    checkUrl() {
      this.isSeparatePage = window.location.href.includes('sbor_bazy_po_inn');
    },
  },
};
</script>

<style>
.vm--overlay {
  height: 100% !important;
}
</style>

<style scoped>
    .actualization__dashboard {
        display: flex;
        flex-direction: column;
    }

    .actualization__wrapper {
      padding: 15px 20px;
      background: var(--background-color-configurator);
      z-index: 1;
      box-shadow: rgb(153 153 153) 0 -1px 5px 0;
      border-radius: 10px 10px 0 0;
    }

    .separate_actualization__wrapper {
      border-radius: 0 10px 5px 5px !important;
    }

    .actualization__configurator {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .inn_parser_banner_wrapper {
      margin-bottom: 16px;
      top: 20px;
    }

    .inn_parser_banner_wrapper .inn_parser_banner {
      margin-left: -1px;
      width: 100.4%;
      height: auto;
      background-color: #f7f7f7;
      box-shadow: #608513 1px 12px 10px -10px;
      cursor: pointer;
    }

</style>
