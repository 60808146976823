<!-- компонент реализуется уникально для каждого радела -->

<template>
  <NotificationComponent
    v-if="!fileIsUploaded && !name"
    flipper-position="without-flipper"
  >
    Пока вы не загрузили свой файл, мы не можем определить,
    сколькими данными мы сможем его дополнить.
    Загрузите файл, и система выдаст вам расчет.
  </NotificationComponent>
  <NotificationComponent
    v-if="fileIsUploaded && totalCost > 0"
    flipper-position="flipper"
  >
    Это количество фирм, которое мы можем актуализировать по вашему списку.
    Оплатите и скачайте прямо сейчас!
  </NotificationComponent>
</template>

<script>
import NotificationComponent from '../../../common_components/NotificationComponent/NotificationComponent';

export default {
  name: 'NotificationList',
  components: {
    NotificationComponent,
  },

  props: {
    fileIsUploaded: Boolean,
    totalCost: Number,
    name: String,
  },
};
</script>

<style scoped src="../../../common_components/NotificationComponent/NotificationComponent.css" />
