<template>
  <div class="upload_pane">
    <div class="headerBlock">
      <div class="headerBlock_title">
        Загрузить файл для Актуализации
      </div>
      <span
        class="how-it-works-button"
        @click="openCloseHowItWorks"
      >
        Как это работает?
      </span>
    </div>
    <UploadDropZone
      @uploadFile="uploadFile"
    />
    <NotificationList
      :file-is-uploaded="fileIsUploaded"
      name="uploadPanel"
    />
    <div class="additional-block-wrapper">
      <div class="additional-block">
        <AdditionalSettings />
        <AdditionalFilters />
      </div>
      <span
        v-if="!fileIsAnalyzed && fileIsUploaded"
        class="eb-button-prime small-button"
        @click="analyzeClientFile"
      >
        Рассчитать
      </span>
      <span
        v-if="fileIsAnalyzed && fileIsUploaded"
        class="eb-button-prime small-button"
        @click="scrollToResult"
      >
        Перейти к результату
      </span>
    </div>

    <HowItWorksTab
      v-if="isHowItWorksVisible"
      @close="openCloseHowItWorks"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import UploadDropZone from '@/actualization_service/components/UploadDropZone/UploadDropZone.vue';
import AdditionalSettings from './AdditionalSettings/AdditionalSettings.vue';
import AdditionalFilters from '../AdditionalFilters/AdditionalFilters.vue';
import NotificationList from '../NotificationList/NotificationList.vue';
import HowItWorksTab from '../HowItWorksPopup/HowItWorksPopup.vue';

export default {
  name: 'UploadPanel',
  components: {
    UploadDropZone,
    HowItWorksTab,
    NotificationList,
    AdditionalFilters,
    AdditionalSettings,
  },

  data() {
    return {
      isHowItWorksVisible: false,
    };
  },

  computed: {
    ...mapState('uploadFile', ['fileIsAnalyzed']),

    ...mapGetters('uploadFile', ['fileIsUploaded']),
  },

  created() {
    this.initializeXlsxData();
  },

  methods: {
    ...mapMutations([
      'resetChosenData',
      'showPreloader',
      'closePreloader',
    ]),

    ...mapMutations('uploadFile', [
      'initializeXlsxData',
      'setFileIsAnalyzed',
      'resetFile',
    ]),

    ...mapActions(['analyzeClientFile']),
    ...mapActions('uploadFile', [
      'uploadClientFile',
    ]),

    openCloseHowItWorks() {
      this.isHowItWorksVisible = !this.isHowItWorksVisible;
    },

    uploadFile(file) {
      try {
        window.yaCounter23743225.reachGoal('upload_actual');
      } catch (e) {
        console.error(e);
      }
      if (file !== undefined) {
        this.showPreloader('Загрузка');
        const fileData = new FormData();
        fileData.append('file', file);
        this.uploadClientFile(fileData)
          .then(() => {
            this.closePreloader();
            this.resetChosenData();
            this.setFileIsAnalyzed(false);
          })
          .catch(() => {
            alert(
              'Произошла ошибка при загрузке файла! '
              + 'Просьба связаться с тех. поддержкой '
              + 'по тел.: 8 800 775-29-12 или info@export-base.ru',
            );
            this.setFileIsAnalyzed(false);
            this.closePreloader();
            this.resetChosenData();
            this.resetFile();
          });
      }
    },

    scrollToResult() {
      window.scrollTo({
        top: window.pageYOffset
          + document.getElementById('actualization_calculation_result').getBoundingClientRect().top
          - 100,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.headerBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.headerBlock_title {
  font-size: 20px;
  font-weight: bold;
}

.upload_pane {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.additional-block {
  display: flex;
  align-items: center;
  gap: 15px;
}

.additional-block-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

    .chosen_file_details__wrapper {
      margin: 30px 0;
    }

    .explanation {
        margin-bottom: 30px;
        font-style: italic;
        text-align: start;
    }

    .actualization__top_detach {
      margin-top: 10px;
    }

    @media (max-width: 1200px) {
        .explanation_wide {
            display: none;
        }
    }

    .how-it-works-button {
      font-size: 12px;
      color: var(--color-green-prime);
      text-decoration: underline;
      cursor: pointer;
    }
</style>
