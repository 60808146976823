import api from '../../api';

const uploadFile = {
  namespaced: true,

  state: {
    file: undefined,
    insideNameOfUploadedFile: '',
    // двумерный массив с данными из клиентского xlsx-файла для отображения
    xlsxData: [],
    alertText: '',
    fileIsAnalyzed: false
  },

  getters: {
    nameOfUploadedFile(state) {
      return state.file !== undefined ? state.file.name : '';
    },
    insideNameOfUploadedFile(state) {
      return state.insideNameOfUploadedFile !== undefined ? state.insideNameOfUploadedFile : '';
    },
    fileIsUploaded(state) {
      return state.file !== undefined;
    }
  },

  mutations: {
    initializeXlsxData(state) {
      const cells = [];

      for (let i = 0; i < 10; i++) {
        cells.push('');
      }

      for (let i = 0; i < 7; i++) {
        state.xlsxData.push(cells);
      }
    },

    setUploadedFile(state, file) {
      state.file = file;
    },

    resetFile(state) {
      state.file = undefined;
    },

    setUploadResponseData(state, data) {
      state.xlsxData = [];

      data.demoXlsx.forEach(item => { state.xlsxData.push(item) });
      state.insideNameOfUploadedFile = data.nameOfUploadedFile;
    },

    setAlertText(state, text) {
      state.alertText = text;
    },

    setFileIsAnalyzed(state, data) {
      state.fileIsAnalyzed = data;
    }
  },

  actions: {
    uploadClientFile({commit}, fileFormData) {
      return api.uploadClientFile(fileFormData)
        .then(response => {
          if (response.status === true) {
            commit('setUploadedFile', fileFormData.get('file'));
            commit('setUploadResponseData', response.data);
            commit('setAlertText', '');
          } else {
            commit('setAlertText', response.message);
            commit('resetFile');
          }
        });
    },
  }
};

export default uploadFile;
