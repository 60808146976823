<template>
  <div
    class="scrollable-X"
    style="width: calc(100% + 1px);"
  >
    <table>
      <thead>
        <tr>
          <th class="indexCell" />
          <th
            v-for="column in columns"
            class="columnHeading"
            @click="chooseColumn(column.value)"
          >
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in rows"
        >
          <td class="indexCell">
            {{ index }}
          </td>
          <td
            v-for="(value, columnIndex) in row"
            class="availableToSelect"
            :class="{'chosenColumn': columnIndex === chosenColumn}"
            @click="chooseColumn(columnIndex)"
          >
            <span
              class="availableToSelectText"
            >
              {{ value }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'UploadedTable',
  props: {
    rows: Array,
    columns: Array,
    chosenColumn: Number,
  },

  emits: ['chooseColumn'],

  methods: {
    chooseColumn(columnIndex) {
      this.$emit('chooseColumn', columnIndex);
    },
  },
};
</script>

<style scoped>

.indexCell {
  min-width: 16px;
}
.indexCell, .columnHeading {
  background-color: #eee;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
}

td, th {
  border: solid 1px #999;
  width: 71px;
  height: 18px;
  font-size: 11px;
}

th:first-child {
  border-top-left-radius: 5px;
}

th:last-child {
  border-top-right-radius: 5px;
}

tbody tr:last-child .indexCell {
  border-bottom-left-radius: 5px;
}

tbody tr:last-child .availableToSelect:last-child {
  border-bottom-right-radius: 5px;
}

.availableToSelectText {
  display: block;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 3px;
}

.availableToSelect:hover::after,
.chosenColumn::after {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  width: inherit;
  z-index: -1;
  background-color: #a6dea9;
}
</style>
