<template>
  <div class="actualization__additional_filters__container">
    <div class="actualization__additional_filters_checkbox">
      <span
        class="text"
        @click="() => show = !show"
      >
        Дополнительные фильтры
      </span>
    </div>
    <NotificationComponent
      v-if="show"
      class="actualization__additional_filters__list"
      display-mode="overlay"
    >
      <p>
        Если вы не нашли подходящий фильтр среди представленных,
        свяжитесь с нами по телефону<br>
        <a
          class="actualization__callto_link_decoration"
          target="_blank"
          href="callto:+78007752912"
        >
          8 (800) 775-29-12
        </a>
        или напишите нам:
        <a
          class="actualization__mailto_link_decoration"
          target="_blank"
          href="mailto:info@export-base.ru"
        >
          info@export-base.ru.
        </a>
      </p>
      <br>
      <p>
        Мы поможем вам в индивидуальном порядке.
      </p>
    </NotificationComponent>
  </div>
</template>

<script>
import NotificationComponent from '@/common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'AdditionalFilters',
  components: { NotificationComponent },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.actualization__additional_filters__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 15px;
}

.actualization__additional_filters_checkbox {
  display: flex;
  flex-direction: row;
}

.actualization__additional_filters__list {
  z-index: 1;
  width: 370px;
  margin-top: 35px;
  text-align: start;
}

.text {
  cursor: pointer;
  position: relative;
  font-size: 14px;
}

.text::after {
  content: '';
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 21px;
  height: 21px;
  background: url('/dist/css/images/arrow_for_index.png') center center no-repeat;
  background-size: contain;
}

.actualization__callto_link_decoration {
  text-decoration: none;
}

.actualization__mailto_link_decoration {
  font-weight: bold;
  color: #1b88c1;
}
</style>
