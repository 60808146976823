<template>
  <div class="container">
    <Card
      :total-cost="statistics.total_cost"
      :total-count="statistics.count.total"
      :visible-discount="statistics.visible_discount"
      :relative-discount="statistics.relative_discount"
      @demoClick="getDemoFile(dataForDemoFile)"
      @downloadButtonClick="downloadClick"
    >
      <template v-slot:notifications>
        <NotificationList
          :total-cost="statistics.total_cost"
          :file-is-uploaded="fileIsUploaded"
        />
      </template>
    </Card>
    <PayPopup
      :visible-pay-popup="visiblePayPopup"
      :email="email"
      :order-id="orderId"
      :client-id="clientId"
      :customer-is-authorized="customerIsAuthorized"
      :client-balance="clientBalance"
      :cost="statistics.total_cost"
      :count-companies="statistics.count.total"
      :user-discount="userDiscount || 0"
      :initial-tab="initialTab"
      :show-order-preloader="showOrderPreloader"
      payment-target="inn_parser"
      service="actualization"
      @close-popup="closePayPopup"
      @input-email="changeEmail"
      @create-order="createOrder"
      @create-bill="createBill"
      @init-login="initLogin"
    >
      <template v-slot:description>
        <Description />
      </template>
    </PayPopup>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import Description from '@/actualization_service/components/Description/Description.vue';
import PayPopup from '@/common_components/PayPopup/PayPopup.vue';
import Card from '../../../common_components/Card/Card.vue';
import NotificationList from '../NotificationList/NotificationList.vue';

export default {
  name: 'Basket',
  components: {
    Description,
    PayPopup,
    Card,
    NotificationList,
  },
  computed: {
    ...mapState(['statistics']),
    ...mapState('payPopup', [
      'visiblePayPopup',
      'email',
      'orderId',
      'clientId',
      'customerIsAuthorized',
      'clientBalance',
      'userDiscount',
      'initialTab',
      'showOrderPreloader',
    ]),

    ...mapGetters('uploadFile', ['fileIsUploaded']),
    ...mapGetters(['dataForDemoFile']),
    ...mapGetters('payPopup', [
      'getUrlForBill',
    ]),
  },

  async created() {
    await this.getAuthStatus();

    if (this.customerIsAuthorized) {
      await this.getUserData();
    }
  },

  methods: {
    ...mapMutations('payPopup', [
      'setVisiblePayPopup',
      'setEmail',
      'setTypePayment',
      'setOldBeznalData',
      'setInitialTab',
      'setShowOrderPreloader',
    ]),

    ...mapActions(['getDemoFile']),
    ...mapActions('payPopup', {
      createOrderStore: 'createOrder',
    }),

    ...mapActions('payPopup', [
      'getAuthStatus',
      'getUserData',
      'getUserDiscount',
    ]),

    changeEmail(email) {
      this.setEmail(email);
      this.getUserDiscount(email);
    },

    downloadClick() {
      if (this.statistics.total_cost === 0) {
        return echo('Выберите больше фирм', 6000, 'warning');
      }
      this.setVisiblePayPopup(true);
    },

    closePayPopup() {
      this.setVisiblePayPopup(false);
    },

    async createOrder() {
      this.setShowOrderPreloader(true);

      this.setTypePayment('0');
      await this.getUserDiscount(this.email);
      await this.createOrderStore();

      this.setShowOrderPreloader(false);
    },

    addEventForBalanceBlock() {
      document.body.addEventListener('open-balance-block', async () => {
        await this.getAuthStatus();
        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setInitialTab('BalanceForm');
        await this.setVisiblePayPopup(true);
        this.setInitialTab('');
      });
    },

    initLogin() {
      window.open_balance_block = true;
      this.addEventForBalanceBlock();
      this.setVisiblePayPopup(false);
      document.dispatchEvent(new Event('openAuthWrapper'));
    },

    async createBill(data) {
      this.setTypePayment('1');
      this.setOldBeznalData(data);
      await this.createOrderStore();
      location.href = this.getUrlForBill;
    },
  },
};
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
  }
</style>
