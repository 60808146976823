<template>
  <div
    id="actualization_calculation_result"
    class="panel-container"
  >
    <div class="card">
      <div
        v-if="!fileIsUploaded"
        class="default-section"
      >
        <img
          src="/images/icons/simple_shapes/circle-attention.svg"
          alt="Предупреждение"
        >
        <div class="statistics__default-text">
          Пусто,
          <span
            class="statistics__default-text_file"
            @click="scrollToTop"
          >добавьте свой файл</span> с данными, <br>
          которые хотите актуализировать и дополнить
        </div>
      </div>
      <div
        v-else-if="!fileIsAnalyzed"
        class="default-section"
      >
        <img
          src="/images/icons/simple_shapes/circle-attention.svg"
          alt="Предупреждение"
        >
        <div class="statistics__default-text statistics__default-text_calculate">
          Нажмите
          <span
            class="statistics__default-text_file"
            @click="analyzeClientFile"
          >"Рассчитать"</span>
          , чтобы система
          проанализировала ваш файл
        </div>
      </div>
      <div
        v-else
        class="statistics-section"
      >
        <div class="statistics-section-header">
          <img
            v-if="statistics.count.full > 0"
            src="/images/icons/simple_shapes/check-circle.svg"
            alt="img"
          >
          <img
            v-else
            src="/images/icons/simple_shapes/cross-circle.svg"
            alt="img"
          >
          <p class="statistics-section__text">
            Файл обработан
          </p>
        </div>
        <p class="statistics-section__text">
          Найдено строк с {{ dataTypeTitleDeclination(chosenDataType, 'ами') }}: {{ statistics.count.full }}
        </p>
        <div class="statistic-section__description">
          <p
            v-if="statistics.count.full === 0"
            class="statistics-section__text"
          >
            Пожалуйста,
            <b
              class="statistics-section__link"
              @click="scrollToTop"
            >проверьте</b>,
            <br>
            правильно ли указан столбец с данными для
            <br>
            актуализации
          </p>
          <div
            v-else
          >
            <p class="statistics-section-subheader">
              Мы нашли данных
            </p>
            <p
              v-for="filter in getMainSelectedFilters"
              class="statistics-section__text"
            >
              {{ filter.title }}:
              <b>
                {{ (filter.name === 'company_name_okved')
                  ? statistics.count['okved_contact_person'] : statistics.count[filter.name] || 0 }}
              </b> шт.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Basket />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import Basket from '../Basket/Basket.vue';

export default {
  name: 'StatisticsPanel',

  components: {
    Basket,
  },

  computed: {
    ...mapState('uploadFile', ['fileIsAnalyzed']),
    ...mapGetters('uploadFile', ['fileIsUploaded']),
    ...mapState(['statistics', 'chosenDataType']),
    ...mapGetters(['getFilters']),

    getMainSelectedFilters() {
      return this.getFilters.filter((filter) => filter.selected);
    },
  },

  methods: {
    ...mapActions(['analyzeClientFile']),

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    dataTypeTitleDeclination(dataType, ending = '') {
      switch (dataType.value) {
        case 'phone':
          return dataType.shortTitle.toLowerCase() + ending;
        case 'email':
          return dataType.shortTitle.toLowerCase();
        default:
          return dataType.title;
      }
    },
  },
};
</script>

<style scoped>
.card {
  z-index: 40;
  box-sizing: border-box;
  width: 100%;
  padding: var(--cart-padding);
  border-radius: var(--cart-border-radius);
  background-color: var(--background-color-configurator);
  box-shadow: var(--cart-box-shadow);
}

.statistics-section-header {
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
}

.statistics-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.statistics-section-subheader {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.panel-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--margin-left-cart);
  background-color: #f7f7f7;
  padding-top: var(--margin-under-configurator);
}

.default-section {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: flex-start;
}

.statistics__default-text {
  font-size: 16px;
  line-height: 1.3;
}

.statistics__default-text_file {
  cursor: pointer!important;
  text-decoration: none!important;
  font-weight: bold;
}

.statistics__default-text_calculate {
  max-width: 270px;
}

.statistics-section__text {
  line-height: 1.2;
}

.statistics-section__link {
  cursor: pointer!important;
}
</style>
