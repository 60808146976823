<template>
  <div class="filters_wrapper">
    <div class="title">
      Какие данные будут добавлены в вашу базу:
    </div>
    <div class="filters">
      <div class="filters_left_section">
        <div
          v-for="(filter, ind) in getFilters.slice(0, 5)"
          :key="filter.name"
          class="single_filter_wrapper"
          :class="ind === (groupSize - 1) ? 'end-of-group' : ''"
        >
          <Field
            :filter="filter"
            :sub-filters="getSubFilters(filter.name)"
          />
        </div>
      </div>

      <div class="filters_right_section">
        <div
          v-for="(filter, ind) in getFilters.slice(5)"
          :key="filter.name"
          class="single_filter_wrapper"
          :class="ind === (groupSize - 1) ? 'end-of-group' : ''"
        >
          <Field
            :filter="filter"
            :sub-filters="getSubFilters(filter.name)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Field from '../Field/Field.vue';

export default {
  name: 'Fields',
  components: { Field },

  computed: {
    ...mapState([
      'subFilters',
    ]),

    ...mapGetters([
      'getFilters',
    ]),

    groupSize() {
      return 5;
    },
  },

  methods: {
    getSubFilters(filterName) {
      return this.subFilters[filterName];
    },
  },
};
</script>

<style scoped>
.filters_wrapper {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  padding: 15px;
}

.filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 12px;
}

.filters_left_section {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #DDDDDD;
}

.single_filter_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.single_filter_wrapper.end-of-group {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 16px;
}
</style>
