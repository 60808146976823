<template>
  <div class="row-fields">
    <p class="row-fields__description">
      Поля:
    </p>
    <ul class="row-fields-list">
      <li
        v-for="row in getFilters"
        :key="row.name"
        class="row-fields-item"
      >
        <img
          v-if="row.selected"
          alt="mark"
          src="/dist/css/images/tick_small.png"
          class="row-fields__mark"
        >
        <p
          v-if="row.selected"
          class="row-fields__text"
        >
          {{ row.title }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RowFields',

  computed: {
    ...mapGetters([
      'getFilters',
    ]),
  },
};
</script>

<style>
.row-fields{
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: flex-start;
}

.row-fields-item{
  display: flex;
  align-items: center;
  font-weight: normal;
}
</style>
