import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import Dashboard from './components/Dashboard/Dashboard.vue';
import store from './store/index';

const el = document.getElementById('actualization_service');
if (el) {
    const app = createApp(Dashboard);
    const vfm = createVfm();
    initSentry(app);
    app.use(store);
    app.use(vfm);
    window.actualization_service = app.mount('#actualization_service');
}
