<template>
  <div class="field">
    <div class="filter__item_switch">
      <div
        :class="{'filter__item_detach': hasSubFilters, 'filter-disabled': filter.disabled}"
        @click="showAlert(filter)"
      >
        <ToggleSwitch
          :id="filter.name"
          :title="filter.title"
          :chosen="filter.selected"
          :disabled="getSelectedRequiredFilters.length === 1 && filter.selected"
          :mode="['exportbase']"
          @set-chosen-item="toggleFilter(filter.name)"
        />
        <div
          v-if="hasSubFilters && filter.selected"
          class="container_subFilter_title-list"
        >
          <SubFilterCheckBoxes
            :items="subFilters"
            :mode="['dark']"
            :space-between="5"
            :filter-name="filter.name"
            :required-at-least-one="filter.requiredSubFilters"
            @chooseCheckBox="changeSubFilter"
          />
        </div>
      </div>
    </div>
    <div class="filter__item_checkbox">
      <div :class="hasSubFilters ? 'filter__item_detach' : ''">
        <div @click="showFilterAlert(filter)">
          <CheckBox
            :id="filter.name"
            :chosen="filter.selected && !filter.partiallySelected"
            :disabled="!filter.selected || filter.disabled"
            :partially-fill="filter.selected && filter.partiallySelected"
            :title="getFieldText"
            :mode="getCheckBoxMode"
            @change="changeFilter(filter.name)"
          />
        </div>
        <div
          v-if="filter.selected && filter.name === 'phone'"
          class="actualization__explain_phone_subFilter__wrapper"
        >
          {{ getExplanationText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ToggleSwitch from 'toggle-switch';
import CheckBox from 'checkbox';
import SubFilterCheckBoxes from '../SubFilters/SubFilterCheckBoxes.vue';
import ExplainHelper from '../../helper/ExplainHelper';

export default {
  name: 'Field',
  components: {
    SubFilterCheckBoxes,
    ToggleSwitch,
    CheckBox,
  },

  props: {
    filter: Object,
    subFilters: {
      type: Array,
      required: false,
    },
  },

  computed: {
    ...mapGetters(['getSelectedRequiredFilters']),
    getFieldText() {
      if (this.filter.selected) {
        if (this.filter.partiallySelected) {
          return 'Заполнено там, где есть';
        }
        return 'Обязательное поле';
      }
      return 'Поле не выбрано';
    },

    getCheckBoxMode() {
      return this.filter.selected && !this.filter.disabled ? ['dark'] : ['dark', 'disabled'];
    },

    hasSubFilters() {
      return this.subFilters !== undefined;
    },

    getExplanationText() {
      return ExplainHelper.explainPhoneSubFilters({
        phoneFilter: this.filter,
        phoneSubFilters: this.subFilters,
      });
    },
  },

  methods: {
    ...mapMutations([
      'changeFilter',
    ]),

    ...mapActions([
      'toggleFilter',
      'changeSubFilter',
    ]),

    showFilterAlert(filter) {
      if (filter.disabled) {
        window.echo('Необходимо выбрать минимум 2 фильтра для активации расширенных настроек');
      } else if (!filter.selected) {
        window.echo('Данный фильтр не выбран');
      }
    },

    showAlert(filter) {
      if (filter.disabled) {
        window.echo(`В вашей выгрузке уже включен ${filter.title}`);
      }
    },
  },
};
</script>

<style scoped>

    .field {
        display: flex;
    }

    .field :deep(.checkbox-button_disabled) {
      opacity: 0;
    }

    .filter__item_switch {
        display: block;
        width: 200px;
    }

    .filter__item_checkbox {
        display: block;
        text-align: left;
        width: 155px;
        margin-left: 5px;
    }

    .container_subFilter_title-list {
        margin-top: 15px;
        margin-left: 25px;
    }

    .actualization__explain_phone_subFilter__wrapper {
        display: flex;
        box-sizing: border-box;
        padding: 15px 10px 0;
        font-style: italic;
        color: rgb(0 0 0 / 50%);
    }

    .filter-disabled {
      color: gray;
    }
</style>
