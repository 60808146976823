import api from '@/actualization_service/api/index';

export default {
  namespaced: true,

  state: {
    email: '',
    orderId: '',
    clientId: 0,
    clientBalance: 0,
    customerIsAuthorized: false,
    visiblePayPopup: false,
    typePayment: '',
    oldBeznalData: {},
    initialTab: '',
    userDiscount: 0,
    showOrderPreloader: false,
  },
  getters: {
    getUrlForBill(state) {
      let url = window.location.origin + '/api/bill?email_for_beznal=' + state.oldBeznalData.email_for_beznal
      + '&umb_schet=' + state.oldBeznalData.umb_schet + '&count_for_beznal=' + state.oldBeznalData.count_for_beznal
      + '&cost_for_beznal=' + state.oldBeznalData.cost_for_beznal + '&inn=' + state.oldBeznalData.inn
      + '&nazv=' + state.oldBeznalData.nazv + '&is_actualization=1';

      if (state.oldBeznalData.urAdr.length !== 0) {
        url += `&urAdr=${state.oldBeznalData.urAdr
        }&director=${state.oldBeznalData.director}`;
      }
      return url;
    },

    getDataToCreateOrder(state, getters, rootState, rootGetters) {
      return {
        file: rootGetters.clientFileData,
        filters: rootGetters.settings,
        type: 'main',
        user: {
          email: state.typePayment === '0' ? state.email : state.oldBeznalData.email_for_beznal,
          type: state.typePayment,
          beznal: state.oldBeznalData,
        },
      };
    },
  },
  mutations: {
    setOrderId(state, value) {
      state.orderId = value;
    },
    setOldBeznalData(state, value) {
      state.oldBeznalData = value;
    },
    setClientId(state, value) {
      state.clientId = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setAuthStatus(state, value) {
      state.customerIsAuthorized = value;
    },
    setUserBalance(state, balance) {
      state.clientBalance = balance;
    },
    setTypePayment(state, value) {
      state.typePayment = value;
    },

        setVisiblePayPopup(state, value) {
            state.visiblePayPopup = value;
        },

    setOptionOldBeznalData(state, {option, value}) {
      state.oldBeznalData[option] = value;
    },
    setInitialTab(state, value) {
      state.initialTab = value;
    },
    setUserDiscount(state, value) {
      state.userDiscount = value;
    },
    setShowOrderPreloader(state, value) {
      state.showOrderPreloader = value;
    },
  },
  actions: {
    async getAuthStatus({ commit }) {
      const auth = await api.checkAuth();
      commit('setAuthStatus', Boolean(auth));
    },
    async getUserData({ commit }) {
      const userData = await api.getUserData();
      commit('setEmail', userData.email);
      commit('setUserBalance', userData.balance);
      commit('setUserDiscount', userData.discount);
    },

    async getUserDiscount({commit}, email) {
      const discount = await api.getUserDiscount(email);
      commit('setUserDiscount', discount.disc);
    },

    async createOrder({state, getters, commit}) {
      const res = await api.createOrder(getters.getDataToCreateOrder);
      commit('setOrderId', String(res.id_plan));
      commit('setClientId', Number(res.customer_number));

      if (state.typePayment === '1') {
        commit('setOptionOldBeznalData', {
          option: 'umb_schet',
          value: String(res.id_plan),
        })
      }
    },
  },
};
