import api from '../../api';
import filters from '../../data/filters';

export const actions = {
    getFilters({ commit }) {
        commit('setFilters', filters);
        commit('setSubFilters');
    },
    uploadClientFile({ commit }, file) {
        return api.uploadClientFile(file);
    },
    analyzeClientFile() {
        MInnParserConfiguration.makeFileDataCalculation();
    },
    toggleFilter({
        commit,
        state,
    }, name) {
        commit('toggleFilter', name);

        if (state.subFilters[name] !== undefined) {
            commit('resetSelectnessOfSubFilters', name);
        }
    },
    changeSubFilter({
        commit,
        state,
        getters
    }, payload) {
        if (getters.getSelectedRequiredFilters.length === 1) {
            return;
        }
        commit('changeSubFilter', payload);

        if (
        state.subFilters[payload.mainFilterName] !== undefined
        &&
        payload.forceCloseMainFilter === true
        ) {
            const disabledFilters = state.subFilters[payload.mainFilterName]
            .filter(filter => !filter.selected);

            if (state.subFilters[payload.mainFilterName].length === disabledFilters.length) {
                commit('disableFilter', payload.mainFilterName);
                commit('setSubFilters');
            }
        }
    },

    async getDemoFile({state, commit }, settings) {
        if (!settings.file.name) {
            return window.echo('Нужно загрузить файл');
        }

        if (state.statistics.count.total <= 5) {
            return window.echo('Должно быть найдено больше пяти фирм');
        }
        await commit('showPreloader', 'Формирование демо-файла');
        await api.getDemoFile(settings)
          .then(res => window.location.replace(res.link))
        await commit('closePreloader');
    },
};
