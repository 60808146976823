<template>
    <div class="additional_settings">
        <CheckBox
            title="Показывать компании <br />  в стадии ликвидации"
            :html-title="true"
            :chosen="showClosed && !disabled"
            :mode="getCheckBoxMode"
            :disabled="disabled"
            @change="changeShowClosed"
        />
        <span class="explanation popup"
            data-file="/api/popup/actualization_show_closed_explanation">
        </span>
    </div>
</template>

<script>
    import {mapState, mapMutations} from 'vuex';
    import CheckBox from "checkbox";
    export default {
        name: "AdditionalSettings",
        components: {CheckBox},

        computed: {
            ...mapState([
                'showClosed',
                'chosenDataType'
            ]),
            disabled() {
                const dataTypes = ['phone', 'email'];
                return dataTypes.indexOf(this.chosenDataType.value) !== -1;
            },
            getCheckBoxMode() {
                const classes = ['dark', 'aligntop'];

                if (this.disabled) {
                    classes.push('disabled');
                }

                return classes;
            }
        },

        methods: {
            ...mapMutations([
                'changeShowClosed',
            ])
        },
    }
</script>

<style scoped>
    .additional_settings {
        display: flex;
    }

    .explanation {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-left: 8px;
        background: url("../../../../assets/images/icon_query.png") center center no-repeat;
        cursor: pointer;
    }
</style>
