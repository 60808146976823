export default [
    {
        name: 'company_name_okved',
        title: 'Название / ОКВЭД',
    },
    {
        name: 'phone',
        title: 'Телефон компании',
    },
    {
        name: 'address',
        title: 'Адрес',
    },
    {
        name: 'email',
        title: 'E-mail компании',
    },
    {
        name: 'site',
        title: 'Сайт / соц. сети',
    },
    {
        name: 'okved_contact_person',
        title: 'Руководитель (по ЕГРЮЛ)',
    },
    {
        name: 'ogrn',
        title: 'ОГРН',
    },
    {
        name: 'inn',
        title: 'ИНН',
    },
    {
        name: 'company_size',
        title: 'Числ. / Оборот / Выручка',
    },
    {
        name: 'reg_date',
        title: 'Дата регистрации',
    },
    {
        name: 'kpp_okpo',
        title: 'КПП / OKПO',
    },
];
