export const state = {
  initiallySelectedFilters: {
    'company_name_okved': { totally: true },
    'phone': { totally: true },
    'email': { totally: false },
  },
  filtersWithRequiredSubFilters: {
    'phone': true
  },
  filters: [],
  subFilters: {
    'phone': [],
    'site': [],
  },
  initialSubFilters: {
    'phone': [
      {
        name: 'stationary_phone',
        title: 'стационарные',
        selected: true
      },
      {
        name: 'mobile_phone',
        title: 'мобильные',
        selected: true
      }
    ],
    'site': [
      {
        name: 'site_title_and_description',
        title: 'заголовок и описание',
        selected: false
      },
    ]
  },
  showClosed: false,
  dataTypes: [
    {
      value: 'inn',
      title: 'ИНН'
    },
    {
      value: 'ogrn',
      title: 'ОГРН'
    },
    {
      value: 'phone',
      title: 'Телефон компании',
      shortTitle: 'Телефон',
    },
    {
      value: 'email',
      title: 'E-mail компании',
      shortTitle: 'E-mail',
    },
  ],
  chosenDataType: {},
  columns: [
    {
      value: '0',
      title: 'A'
    },
    {
      value: '1',
      title: 'B'
    },
    {
      value: '2',
      title: 'C'
    },
    {
      value: '3',
      title: 'D'
    },
    {
      value: '4',
      title: 'E'
    },
    {
      value: '5',
      title: 'F'
    },
    {
      value: '6',
      title: 'G'
    },
    {
      value: '7',
      title: 'H'
    },
    {
      value: '8',
      title: 'I'
    },
    {
      value: '9',
      title: 'J'
    },
  ],
  chosenColumn: {},
  delimiters: [
    {
      value: '0',
      symbol: '',
      title: 'Отсутствует'
    },
    {
      value: '1',
      symbol: '\n',
      title: 'Переход на след. строку'
    },
    {
      value: '2',
      symbol: ',',
      title: 'Запятая ( , )'
    },
    {
      value: '3',
      symbol: ';',
      title: 'Точка с запятой ( ; )'
    },
    {
      value: '4',
      symbol: '|',
      title: 'Вертикальная черта ( | )'
    },
  ],
  chosenDelimiter: {},

  statistics: {
    count: {
      total: 0,
      address: 0,
      company_size: 0,
      email: 0,
      full: 0,
      okved_contact_person: 0,
      phone: 0,
      site: 0,
    },
    cost_of_one_firm: 0,
    total_cost: 0,
    firm_cost: 0,
  },

  file: {},

  preloaderShow: false,
  preloaderText: '',
};
