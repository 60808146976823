const METHOD = '/api/actualization_service/';

const api = {
    uploadClientFile: (file) => {
        const requestUrl = METHOD + 'upload_file';
        const requestOptions = {
            method: 'POST',
            body: file
        };

        return fetch(requestUrl, requestOptions)
          .then(res => res.json());
    },

    checkAuth() {
        return fetch('/api/client_is_authenticated', {method: 'GET'})
            .then(res => res.json());
    },

    getUserData() {
        return fetch('/api/authorized_client_data', {method: 'GET'})
            .then(res => res.json());
    },

    getUserDiscount(email) {
        return fetch('/handlers/email_discount.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                email: email,
            }),
        })
            .then(res => res.json())
            .catch(() => {
                echo(
                    `Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)`,
                    6000,
                    'error',
                );
            })
    },

    calculateStatistics: settings => {
        const requestUrl = METHOD + 'calculate_statistics';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(settings)
        };

        return fetch(requestUrl, requestOptions)
          .then(res => res.json());
    },

    getDemoFile: (settings) => {
        return fetch(METHOD + 'create_demo_file', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(settings),
        }).then(res => res.json());
    },

    createOrder(data) {
        return fetch(METHOD + 'create_order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(res => res.json());
    },
};

export default api;
