// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_query.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".subFilter-list[data-v-0895c980] {\n    display: flex;\n    flex-direction: column;\n    list-style-type: disc;\n}\n.subFilter-row[data-v-0895c980] {\n    display: flex;\n    flex-direction: row;\n    width: 180px;\n    align-items: center;\n    gap: 5px;\n}\n.subFilter-item[data-v-0895c980] {\n    padding: 3px 5px;\n}\n.subFilter-item-active[data-v-0895c980] {\n    background-color: var(--color-button-prime-hover);\n    color: white;\n}\n.explanation[data-v-0895c980] {\n    width: 17px;\n    height: 17px;\n    margin-left: 3px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n    cursor: pointer;\n}\n", ""]);
// Exports
module.exports = exports;
