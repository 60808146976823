<template>
  <Teleport to="body">
    <div
      class="overlay_background"
      @click="$emit('close')"
    />
    <div class="how-it-works-modal">
      <div
        class="how-it-works-modal-close-button"
        @click="$emit('close')"
      />
      <div class="how-it-works-modal-content">
        <ul class="how-it-works-steps">
          <li
            v-for="(step, index) in steps"
            :key="index"
            class="how-it-works-step"
          >
            <div class="how-it-works-step__number">
              {{ step.number }}
            </div>
            <h3 class="how-it-works-step__title">
              {{ step.title }}
            </h3>
            <p class="how-it-works-step__text" v-html="step.text"></p>
          </li>
        </ul>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: 'HowItWorksTab',

  emits: ['close'],
  data() {
    return {
      steps: [
        {
          number: 1,
          title: 'Загрузка файла',
          text: `Нажмите кнопку <b>"Загрузить файл"</b>. В появившемся окне добавьте Ваш файл.`,
        },
        {
          number: 2,
          title: 'Выбор типа данных',
          text: `Выберите данные какого типа (ИНН, ОГРН, Телефон, E-mail) содержатся в вашем файле, а также столбец, в котором они расположены, и нажмите <b>"Рассчитать"</b>. Конфигуратор рассчитает, какое количество данных мы можем Вам предоставить.`,
        },
        {
          number: 3,
          title: 'Выбор полей',
          text: `После расчета количества данных выберите необходимые информационные поля в списке (телефон, адрес и индекс, E-mail, сайт, руководитель (по ЕГРЮЛ), числ./оборот, дата регистрации, КПП/ОКПО). При необходимости отметьте обязательные поля. Нажмите кнопку <b>"Скачать"</b>.`,
        },
        {
          number: 4,
          title: 'Оплата заказа',
          text: `В появившемся окне введите Ваш E-mail, выберите наиболее удобный для Вас способ оплаты и нажмите <b>"Оплатить"</b>. После успешной оплаты Вы сможете скачать результативный файл базы данных. Просто перейдите в <b>личный кабинет</b>, найдите нужный заказ на актуализацию базы данных и нажмите <b>"Скачать"</b>. Система автоматически сформирует и предоставит Вам файл базы данных.`,
        },
      ],
    };
  },
};
</script>

<style scoped>
.how-it-works-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  width: 800px;
  height: 600px;
  border: 3px solid #FFD800;
  box-shadow: 0 0 10px #333;
}

.how-it-works-modal-close-button {
  background-image: url("../../../assets/images/close.png");
  background-size: 30px;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  right: -12px;
  width: 30px;
  height: 28px;
  cursor: pointer;
  z-index: 10000;
}

.how-it-works-modal-content {
  width: 100%;
  height: 100%;
  line-height: 1.3;
  overflow: hidden;
}

.how-it-works-steps {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.how-it-works-step {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.how-it-works-step__number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  border: 3px solid var(--color-button-prime-hover);
  padding: 10px;
  width: 40px;
  height: 40px;
  grid-row: 1/3;
  align-self: flex-start;
  top: 15px;
  font-weight: bold;
}

.how-it-works-step__title {
  font-size: 18px;
  margin-top: 10px;
  padding-left: 10px;
  font-weight: bold;
}

.how-it-works-step__text {
  font-size: 16px;
  padding-left: 10px;
}

@media (max-width: 800px) {
  .how-it-works-modal {
    width: 95%;
  }
}

.overlay_background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 202;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.5;
  background-color: #000;
}
</style>
