import { createStore } from 'vuex';
import uploadFile from './module/uploadFile';
import { state } from './main/state';
import { getters } from './main/getters';
import { mutations } from './main/mutations';
import { actions } from './main/actions';
import payPopup from "@/actualization_service/store/module/payPopup";

const store = createStore({
  modules: {
    uploadFile,
    payPopup,
  },
  state,
  getters,
  mutations,
  actions
});

export default store;
