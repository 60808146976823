export const mutations = {
  resetChosenData(state) {
    state.chosenDataType = state.dataTypes[0];
    state.chosenColumn = state.columns[0];
    state.chosenDelimiter = state.delimiters[0];
  },

  setChosenDataType(state, value) {
    state.chosenDataType = state.dataTypes.find(dataType => dataType.value === value);
  },

  setChosenColumn(state, value) {
    state.chosenColumn = state.columns[value];
  },

  setChosenDelimiter(state, value) {
    state.chosenDelimiter = state.delimiters[value];
  },

  changeStatistics(state, statistics) {
    state.statistics = statistics;
  },

  /**
   * Важно следить за значением переменной needRecalculation.
   * Если needRecalculation === true,
   * то на серверной части производится полный пересчет кол-ва найденных данных
   * и на основе нового кол-ва - цена (с учетом изменения суммы стоимостей выбранных фильтров),
   * иначе - пересчитывается только цена (меняется сумма стоимостей выбранных фильтров).
   *
   * Случаи, когда пересчет кол-ва данных необходим в данном методе:
   * - состояние фильтра "обязателен" и его выключают
   * - фильтр включают и его изначальное состояние "обязателен"
   *
   * Фильтры, изначальное состояние которых "обязателен": Название/ОКВЭД, Телефон. (далее initiallyRequired)
   * Изначальное состояние остальных - "необязателен".
   *
   * Требуемое поведение: при включении фильтров Название/ОКВЭД или Телефон,
   * изначальное состояние этих фильтров должно быть "обязателен",
   * при включении остальных фильтров изначальное состояние соответствующих фильтров
   * должно быть "необязателен".
   */
  toggleFilter(state, name) {
    let needRecalculation = true;
    let selectedFilters = state.filters.filter(filter => filter.selected);
    let partiallySelectedFilters = state.filters.filter(
      filter => filter.partiallySelected
    );
    const filter = state.filters.find(filter => filter.name === name);

    // 1. случай, когда взаимодействуют с единственным выбранным фильтром: ничего не делаем
    if (selectedFilters.length === 1 && selectedFilters[0].name === name) {
      return;
    }

    /**
     * 2. случай, когда выбранных фильтров более 1:
     * ш. 1. проверяем, является ли рассматриваемый фильтр одним из initiallyRequired
     *
     * ш. 2. если рассматриваемый фильтр ВЫКЛЮЧАЮТ,
     * то необходимость needRecalculation пересчета зависит от нынешнего состояния фильтра;
     * иначе - если фильтр ВКЛЮЧАЮТ, то - от результата ш. 1
     *
     * ш. 3. включение/выключение фильтра в зависимости от результата ш. 1
     */
    if (selectedFilters.length >= 1) {
      let condition = state.initiallySelectedFilters[filter.name] !== undefined;
      if (condition === true) {
        condition = condition && state.initiallySelectedFilters[filter.name].totally;
      }

      if (filter.selected) {
        needRecalculation = !filter.partiallySelected;
      } else {
        // производится пересчет всегда,
        // когда включается фильтр "Название/ОКВЭД" или "Телефон"
        needRecalculation = condition;
      }

      if (condition) {
        filter.selected = !filter.selected;
        filter.partiallySelected = false;
      } else {
        const toStatus = !filter.selected;
        filter.selected = toStatus;
        filter.partiallySelected = toStatus;
      }
    }
    // обновление списков всех выбранных и выбранных-необязательных фильтров
    selectedFilters = state.filters.filter(filter => filter.selected);
    partiallySelectedFilters = state.filters.filter(
      filter => filter.partiallySelected
    );

    if (selectedFilters.length === partiallySelectedFilters.length) {
      // если среди выбранных фильтров все - "необязательные",
      // то необходим полный пересчет
      needRecalculation = true;
    }

    // если среди всех остается выбранным лишь 1 фильтр,
    // то его состояние становится "обязателен" (при этом изменить состояние нельзя!),
    // и необходим полный пересчет
    if (selectedFilters.length === 1) {
      selectedFilters[0].partiallySelected = false;
      selectedFilters[0].disabled = true;
      needRecalculation = true;
    } else {
      selectedFilters.forEach(filter => filter.disabled = false);
    }

    // todo: избавиться от вызова MInnParserConfiguration.handleFiltersChange(needRecalculation) (когда-нибудь)
    MInnParserConfiguration.handleFiltersChange(needRecalculation);
  },

  changeFilter(state, name) {
    const filter = state.filters.find(filter => filter.name === name);
    if (filter.selected) {
      filter.partiallySelected = !filter.partiallySelected;
      MInnParserConfiguration.handleFiltersChange(true);
    } else {
      echo(`Пожалуйста, выберите поле ${filter.title}`);
    }
  },

  disableFilter(state, name) {
    const filter = state.filters.find(filter => filter.name === name);
    filter.selected = false;
  },

  changeSubFilter(state, {
    mainFilterName,
    subFilterName
  }) {
    const filter = state.subFilters[mainFilterName]
      .find(filter => filter.name === subFilterName);
    filter.selected = !filter.selected;
    /*
    предполагается, что при изменении подфильтров фильтра,
    в котором нужен хотя бы один включенный подфильтр,
    необходим полный пересчет кол-ва компаний и цены
    */
    MInnParserConfiguration.handleFiltersChange(
      state.filtersWithRequiredSubFilters[mainFilterName] !== undefined
    );
  },

  changeShowClosed(state) {
    state.showClosed = !state.showClosed;
    MInnParserConfiguration.handleFiltersChange(true);
  },

  setFilters(state, filters) {
    state.filters = filters
      .map(filter => {
        const selected = state.initiallySelectedFilters[filter.name] !== undefined;
        const requiredSubFilters = !(
          state.subFilters[filter.name] === undefined
          ||
          state.filtersWithRequiredSubFilters[filter.name] === undefined
        );

        return {
          ...filter,
          'selected': selected,
          'partiallySelected': selected ?
            !state.initiallySelectedFilters[filter.name].totally : false,
          'disabled': false,
          'requiredSubFilters': requiredSubFilters
        };
      });
  },

  /**
   * todo: переделать, когда подфильтры будут не только у фильтра "Телефон"
   * @param state
   */
  setSubFilters(state) {
    state.subFilters['phone'] = [
      {
        name: 'stationary_phone',
        title: 'стационарные',
        selected: true
      },
      {
        name: 'mobile_phone',
        title: 'мобильные',
        selected: true
      }
    ];

    state.subFilters['site'] = [
      {
        name: 'site_title_and_description',
        title: 'заголовок и описание',
        selected: false
      },
    ];
  },

  resetSelectnessOfSubFilters(state, mainFilterName) {
    state.subFilters[mainFilterName].forEach(
      (subFilter, index) => {
        subFilter.selected = state.initialSubFilters[mainFilterName][index].selected;
      }
    );
  },

  showPreloader(state, text) {
    $('#tab_actualization').css('z-index', 0);
    state.preloaderShow = true;
    state.preloaderText = text;
  },

  closePreloader(state) {
    $('#tab_actualization').css('z-index', 80);
    state.preloaderShow = false;
    state.preloaderText = '';
  }
};
