<template>
  <div class="preloader__wrapper">
    <svg class="preloader__spinner" viewBox="0 0 50 50">
      <circle class="preloader__path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <div class="preloader__text">
      <slot>Загрузка</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader'
};
</script>

<style scoped>

  .preloader__wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 20001;
    top: 0;
    left: 0;
  }

  .preloader__text{
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 20px;
    transform: translateX(-50%);
    color: #fff;
    background: #6FB220;
    border-radius: 8px;
    padding: 10px;
  }

  .preloader__spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -70px 0 0 -25px;
    width: 50px;
    height: 50px;
  }
  .preloader__path {
    stroke: #9FFF2F;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

</style>
