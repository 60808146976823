<template>
  <div class="description-list">
    <RowFeature />
    <RowFields />
    <RowFormat />
    <RowRelevant />
  </div>
</template>

<script>

import RowFeature from '@/actualization_service/components/Description/RowFeature.vue';
import RowRelevant from '@/actualization_service/components/Description/RowRelevant.vue';
import RowFormat from '@/actualization_service/components/Description/RowFormat.vue';
import RowFields from '@/actualization_service/components/Description/RowFields.vue';

export default {
  name: 'Description',
  components: {
    RowFeature,
    RowRelevant,
    RowFormat,
    RowFields,
  },
};
</script>

<style scoped>
.description-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: black;
}
</style>
